$mobile-width: 375px;
$tablet-width: 768px;
$desktop-width: 1280px;
$big-desktop-width: 1440px;
$large-desktop-width: 1680px;
$fullhd-desktop-height: 1080px;

@mixin small-mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin for-big-desktop {
  @media (min-width: $big-desktop-width) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: $large-desktop-width) {
    @content;
  }
}

@mixin hover {

  @media screen and (min-width: 1024px) {
    &:hover {
      @content;
    }
  }

}

@mixin full-height {
  height: 100vh;
  @supports (height: 100dvh) {
    min-height: 100dvh;
    height: 100dvh;
  }
}
