@import "../../responsive";

.container {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;

  .logoMobile {

  }

  .formContainer {

    .logo {
      display: none;
    }


  }

  form {
    display: grid;
    gap: 15px;

    .forgotLink, .stayLoggedIn {
      font-size: 1rem;
      line-height: 1.188rem;
      text-transform: uppercase;
      font-weight: 100;
      text-align: right;
      margin-bottom: 20px;
    }

    .stayLoggedIn {
      display: flex;
      flex-direction: row-reverse;
      input {
        margin-left: 10px;
        margin-right: 0;
      }
    }

    button {
      svg {
        margin-left: 0.5rem;
      }
    }
    .submitButton {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include tablet {
    place-content: center;
    gap: 3rem;
  }

  @include desktop {
    .logoMobile {
      display: none;
    }
    .formContainer {
      position: relative;
      width: 40%;

      .logo {
        position: absolute;
        transform: translate(0%, -240px);
        width: calc(100% - 4.5rem);
        display: grid;
        place-content: center;
      }

    }
  }

}
