@import "libs/common/styles/colors";
@import "libs/common/styles/general";

@mixin cardBaseContainer {
  @include baseContainer;
  margin-bottom: 1rem;
}

.card {
  @include card;

  .titleContainer {
    @include cardBaseContainer;

    &.rightHeader {
      @include desktop {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .right {
    @include cardBaseContainer;
    justify-content: flex-end;
    display: flex;

    @include mobile {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr;
      gap: 10px;
      * {
        width: 100%;
      }
    }

    .item {
      display: flex;
      gap: 1.5rem;
    }
  }
}
