$white: #FFF;
$black: #000;
$black-2: #202020;
$pink: #E8CDCD;
$pink-2: #EFC5C5;
$pastel-pink: #EAD7CF;
$pastel-pink-2: #ECD0C7;
$grey: #A2A2A2;
$grey-2: #B9B9B9;
$grey-3: #DADADA;
$grey-4: #D1D1D1;
$mid-grey: #CAD2D6;
$mid-grey-2: #B5C0C7;
$light-grey: #EAEAEA;
$light-grey-2: #F8F8F8;
$light-grey-3: #EEEEEE;
$shadow-grey: #0000001A;
$shadow-grey-2: #00000029;
$red: #FE3E3E;
$red-2: #FF5A5A;
$red-3: #F57272;
$green: #0BAF00;
$green-2: #11C805;
$green-3: #70C96C;
$light-green: #C8DEC7;
$light-green-2: #B9DDB7;
$yellow: #DEB953;
$yellow-2: #FEE356;
$yellow-3: #E4C980;
$light-yellow: #EFE7D1;
$light-yellow-2: #F7E6B8;
$orange: #F15C27;
$orange-2: #F57243;
$orange-3: #ED997A;
$blue: #093D5E;
$blue-2: #0F537E;
$blue-3: #5E8BBC;
$blue-4: #6D8A9C;
$blue-5: #3498DB;
$blue-6: #688699;
$light-blue: #D2DCE7;


:export {
  white: $white;
  black: $black;
  black-2: $black-2;
  pink: $pink;
  pink-2: $pink-2;
  pastel-pink: $pastel-pink;
  pastel-pink-2: $pastel-pink-2;
  grey: $grey;
  grey-2: $grey-2;
  grey-3: $grey-3;
  grey-4: $grey-4;
  mid-grey: $mid-grey;
  mid-grey-2: $mid-grey-2;
  light-grey: $light-grey;
  light-grey-2: $light-grey-2;
  light-grey-3: $light-grey-3;
  shadow-grey: $shadow-grey;
  shadow-grey-2: $shadow-grey-2;
  red: $red;
  red-2: $red-2;
  red-3: $red-3;
  green: $green;
  green-2: $green-2;
  green-3: $green-3;
  light-green: $light-green;
  light-green-2: $light-green-2;
  yellow: $yellow;
  yellow-2: $yellow-2;
  yellow-3: $yellow-3;
  light-yellow: $light-yellow;
  light-yellow-2: $light-yellow-2;
  orange: $orange;
  orange-2: $orange-2;
  orange-3: $orange-3;
  blue: $blue;
  blue-2: $blue-2;
  blue-3: $blue-3;
  blue-4: $blue-4;
  blue-5: $blue-5;
  blue-6: $blue-6;
  light-blue: $light-blue;
}